import type { ChakraProps } from '@chakra-ui/react';
import { Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { Num } from '@ui/components/Num';
import { useWcroBalance } from '@ui/hooks/contracts/useWcroBalance';

export const WcroBalance = (props: ChakraProps) => {
  const wcroBalanceRet = useWcroBalance();

  return (
    <Flex justify="space-between" {...props}>
      <Text>WCRO balance</Text>
      <VStack align="flex-end">
        <HStack>
          <Num value={wcroBalanceRet.data} />
          <Image src="/tokens/cro.svg" boxSize="24px" alt="wcro" />
        </HStack>
      </VStack>
    </Flex>
  );
};
