import { ChainId, SDK } from '@ballz-app/sdk';
import { getAddEthereumChainParameter } from '@ballz-app/wallet';

const genSDK = (chainId: ChainId) => {
  const { chainName, rpcUrls } = getAddEthereumChainParameter(chainId);
  const rpcUrl = rpcUrls[0];
  return new SDK({
    providerOptions: {
      chainName: chainName,
      chainId,
      rpcUrl,
    },
  });
};

const SDK_BY_CHAIN = {
  [ChainId.CRONOS_MAINNET]: genSDK(ChainId.CRONOS_MAINNET),
  [ChainId.CRONOS_TESTNET]: genSDK(ChainId.CRONOS_TESTNET),
};

export const getSDK = (chainId: ChainId) => SDK_BY_CHAIN[chainId];
