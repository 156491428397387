import { resetWhenRouterChange_raw } from '@ui/utils/zustandMiddleware';
import { create } from 'zustand';

interface LoadingStoreProps {
  showLoading: boolean;
  setShowLoading: (showLoading: boolean) => void;
}
export const useLoadingStore = create<LoadingStoreProps>(
  resetWhenRouterChange_raw((set) => ({
    showLoading: false,

    setShowLoading: (showLoading) => {
      set({
        showLoading,
      });
    },
  })),
);
