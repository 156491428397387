import {
  currentWallet,
  getAddEthereumChainParameter,
  useDesiredChain,
  walletConfigs,
} from '@ballz-app/wallet';
import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import type { FC } from 'react';
import { Fragment } from 'react';
import { create } from 'zustand';

import { WalletCard } from './WalletCard';

interface UseWalletModal {
  isOpen: boolean;
  closeModal: () => void;
  openModal: () => void;
}

export const useWalletModal = create<UseWalletModal>((set) => ({
  isOpen: false,
  closeModal: () => {
    set({ isOpen: false });
  },
  openModal: () => {
    set({ isOpen: true });
  },
}));

export const WalletModal: FC<unknown> = () => {
  const isOpen = useWalletModal((s) => s.isOpen);
  const closeModal = useWalletModal((s) => s.closeModal);
  const chainId = useDesiredChain();

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Connect Wallet</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Flex direction="column" gap={2}>
            {walletConfigs.map((v) => (
              <Fragment key={v.name}>
                <WalletCard
                  onClick={() => {
                    closeModal();
                    currentWallet.connectAsCurrentWallet(
                      v.name,
                      getAddEthereumChainParameter(chainId),
                    );
                  }}
                  walletConfig={v}
                />
                <Divider />
              </Fragment>
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
