import type { FlexProps } from '@chakra-ui/react';
import { Button, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { Num } from '@ui/components/Num';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { Fraction } from 'bi-fraction';

import { useAccountModal } from '../useAccountModal';

export type VvsBalanceProps = FlexProps;
export const VvsBalance = (props: VvsBalanceProps) => {
  const openDisclaimer = useAccountModal((s) => s.openDisclaimer);
  // TODO mock data
  const vvsBalance = new Fraction('450.816');

  return (
    <Flex justify="space-between" {...props}>
      <Text>VVS Balance</Text>
      <VStack align="flex-end">
        <HStack>
          <Num value={vvsBalance} />
          <Image src="/tokens/vvs.svg" boxSize="24px" alt="vvs" />
        </HStack>
        <Button
          variant="link"
          color="red.400"
          onClick={() => {
            openDisclaimer(EXTERNAL_LINK.vvsSwapVvs);
          }}
        >
          <Text textStyle="caption1">Get VVS</Text>
        </Button>
      </VStack>
    </Flex>
  );
};
