export const EXTERNAL_LINK = {
  gitbook: 'https://doc.orby.network/',
  twitter: 'https://twitter.com/cronos_labs',
  discord: 'https://discord.com/invite/pahqHz26q4',
  telegram: 'https://t.me/Cronos_Announcements',
  whitepaper: 'https://whitepaper.cronos.org/',
  vvsSwapCro: 'https://vvs.finance/swap?outputCurrency=CRO',
  vvsSwapVvs:
    'https://vvs.finance/swap?outputCurrency=0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03',
} as const;
