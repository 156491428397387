import { Box } from '@chakra-ui/react';
import BuyModalImg from '@public/images/buy-modal-bg.png';

import { StrokeText } from '../StrokeText';

export const ImagePanel = () => {
  return (
    <Box
      height="220px"
      bg={`url(${BuyModalImg.src})`}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      pl="10px"
    >
      <StrokeText
        fontSize="50px"
        strokeWidth="10px"
        strokeColor="#3B254C"
        color="#F8478B"
        pt="20px"
        height="70px"
      >
        BUY BALLZ
      </StrokeText>
      <StrokeText
        fontSize="20px"
        strokeWidth="7px"
        strokeColor="#3B254C"
        color="#47FCE9"
      >
        BUY & SPEED UP!
      </StrokeText>
    </Box>
  );
};
