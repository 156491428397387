import { PostHogEvents, postHogProxy } from '@ballz-app/analytics';
import { fontFamily } from '@ballz-app/chakra';
import { DiscountBk, Star, Steel } from '@ballz-app/icons';
import type { StackProps } from '@chakra-ui/react';
import { Box, HStack, Icon, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useBallzToast } from '@ui/hooks/useBallzToast';
import { useTgInfoStore } from '@ui/views/Index/TgInfoView/useTgInfoStore';
import { useMemo } from 'react';

import { StrokeText } from '../StrokeText';
import { CommodityColors } from './constants';
import { useBuyModal } from './useBuyModal';
import { useGetInvoiceLink } from './useGetInvoiceLink';
import { useLoadingStore } from './useLoadingStore';
import type { FormattedCommodity } from './utils';

const BallTextStyle = {
  color: '#3B254C',
  fontSize: '18px',
  fontFamily: fontFamily.luckiestGuy,
  pt: '5px',
};

export const CommodityItem = ({
  commodity,
  isDiscount = false,
  ...props
}: {
  isDiscount?: boolean;
  commodity: FormattedCommodity;
} & StackProps) => {
  const initData = useTgInfoStore((s) => s.initData);

  const { discountStars, stars, key, balls, discount, discountType } =
    commodity;

  const commodityItemColors = useMemo(() => {
    return CommodityColors[discountType];
  }, [discountType]);

  const tgId = useTgInfoStore((s) => s.user.id);
  const { mutateAsync: getInvoiceLink } = useGetInvoiceLink({ tgId });
  const queryClient = useQueryClient();
  const toast = useBallzToast();
  const [showLoading, setShowLoading] = useLoadingStore((s) => [
    s.showLoading,
    s.setShowLoading,
  ]);
  const [
    selectedCommodityKey,
    setSelectedCommodityKey,
    disableCommodityKey,
    setDisableCommodityKey,
  ] = useBuyModal((s) => [
    s.selectedCommodityKey,
    s.setSelectedCommodityKey,
    s.disableCommodityKey,
    s.setDisableCommodityKey,
  ]);

  return (
    <HStack
      borderRadius="20px"
      border="3px solid #3B254C"
      width="100%"
      cursor="pointer"
      spacing={0}
      boxShadow={selectedCommodityKey === key ? '4.3px 4.3px #3B254C' : 'none'}
      {...props}
      onClick={async () => {
        try {
          if (showLoading || disableCommodityKey === key) return;
          setShowLoading(true);
          setSelectedCommodityKey(key);
          setDisableCommodityKey(key);
          const tg = window.Telegram?.WebApp;
          const response = await getInvoiceLink({
            stars: discountStars,
            balls: balls,
            type: discountType,
          });
          await tg?.openInvoice(response.invoice_link, (status: string) => {
            if (status === 'paid') {
              // status: cancelled / failed / paid
              queryClient.invalidateQueries({
                queryKey: ['game.setting', tgId, initData],
              });
              queryClient.invalidateQueries({
                queryKey: ['user.commodities', tgId, initData],
              });

              postHogProxy.captureWithoutFeature({
                event: PostHogEvents.Buy,
                capture: {
                  commodity: balls,
                  stars: discountStars,
                },
              });
              toast({
                status: 'success',
                description: `You have successfully bought ${balls} Ballz! Time to play!`,
              });
            } else {
              setDisableCommodityKey();
              console.log('Payment failed.');
            }
          });
        } catch (e) {
          console.error(e);
          setDisableCommodityKey();
        } finally {
          setShowLoading(false);
        }
      }}
    >
      <HStack
        p="15px"
        flex="1"
        bg={commodityItemColors.bg}
        bgPosition="center center"
        bgSize="contain"
        borderLeftRadius="16px"
        height="50px"
        position="relative"
      >
        <Text {...BallTextStyle}>{balls} BALLS</Text>
        <Icon as={Steel} />
        {isDiscount && (
          <Box position="absolute" top="-4px" right="0px">
            <DiscountBk
              fill={commodityItemColors.discountColor}
              width="58px"
              height="58px"
            />
            <HStack position="absolute" top="18px" right="10px" spacing={0}>
              <StrokeText
                fontSize="18px"
                strokeWidth="3px"
                strokeColor="#3B254C"
                color="white"
                height="20px"
                transform="rotate(7deg)"
              >
                {discount}
              </StrokeText>
              <StrokeText
                top="6px"
                right="4px"
                position="relative"
                fontSize="12px"
                strokeWidth="2px"
                strokeColor="#3B254C"
                color="white"
                height="20px"
              >
                %
              </StrokeText>
            </HStack>
          </Box>
        )}
      </HStack>
      <HStack
        width={isDiscount ? '130px' : '90px'}
        justify="center"
        p="15px"
        bg="#F8478B"
        borderRightRadius="16px"
        height="50px"
        spacing="10px"
      >
        <Icon as={Star} />
        <HStack>
          {isDiscount && (
            <StrokeText
              fontSize="22px"
              strokeWidth="4px"
              strokeColor="#3B254C"
              color="#47FCE9"
              height="20px"
              mb="2px"
            >
              {discountStars}
            </StrokeText>
          )}
          <Box position="relative" textAlign="center" width="fit-content">
            <Text {...BallTextStyle}>{stars}</Text>
            {isDiscount && (
              <Box
                top="15px"
                transform="rotate(-33deg)"
                bg="#47FCE9"
                position="absolute"
                width="33px"
                height="2px"
                borderRadius="5px"
              />
            )}
          </Box>
        </HStack>
      </HStack>
    </HStack>
  );
};
