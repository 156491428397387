import { create } from 'zustand';

export interface OnboardingModal {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export const useOnboardingModal = create<OnboardingModal>()((set) => ({
  isOpen: false,
  openModal: () => {
    set({
      isOpen: true,
    });
  },
  closeModal: () => {
    set({
      isOpen: false,
    });
  },
}));
