import { postHogSingleton } from './initPostHog';
class PostHogProxy {
    captureWithoutFeature({ event, capture, }) {
        postHogSingleton?.capture(event, capture);
    }
    captureFeature({ event, featureFlag, capture, }) {
        if (postHogSingleton?.isFeatureEnabled(featureFlag)) {
            postHogSingleton.capture(event, capture);
        }
    }
    isFeatureEnabled(featureFlag) {
        return postHogSingleton?.isFeatureEnabled(featureFlag);
    }
    startSessionRecording(featureFlag) {
        if (postHogSingleton?.isFeatureEnabled(featureFlag)) {
            postHogSingleton.startSessionRecording();
        }
    }
    stopSessionRecording() {
        postHogSingleton?.stopSessionRecording();
    }
    isTrackingWallet(address) {
        if (!postHogSingleton)
            return;
        const trackingId = postHogSingleton.get_distinct_id();
        return address === trackingId;
    }
    trackUserByWallet(address) {
        if (!postHogSingleton)
            return;
        postHogSingleton.identify(address, {
            wallet_address: address,
        });
    }
    registerWalletName(wallet) {
        if (!postHogSingleton)
            return;
        postHogSingleton.register({ wallet });
    }
    stopTrackUser() {
        if (!postHogSingleton)
            return;
        postHogSingleton.reset();
    }
}
export const postHogProxy = new PostHogProxy();
