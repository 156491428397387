import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';

import { DefaultContent } from './components/DefaultContent';
import { DisclaimerContent } from './components/DisclaimerContent';
import { useAccountModal } from './useAccountModal';

export const AccountModal = () => {
  const isOpen = useAccountModal((s) => s.isOpen);
  const closeModal = useAccountModal((s) => s.closeModal);
  const view = useAccountModal((s) => s.view);

  return (
    <Modal
      size="md"
      scrollBehavior="inside"
      onClose={closeModal}
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent minH="500px">
        <ModalCloseButton />
        {view === 'default' ? <DefaultContent /> : null}
        {view === 'disclaimer' ? <DisclaimerContent /> : null}
      </ModalContent>
    </Modal>
  );
};
