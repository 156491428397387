import { AccountModal } from './AccountModal';
import { useAccountModal } from './AccountModal/useAccountModal';
import { BuyModal } from './BuyModal';
import { useBuyModal } from './BuyModal/useBuyModal';
import { DevUtils } from './DevUtils';
import { LazyComponent } from './LazyComponent';
import { OnboardingModal } from './OnboardingModal';
import { useOnboardingModal } from './OnboardingModal/useOnboardingModal';
import { useWalletModal, WalletModal } from './Wallet';

export const GlobalComponents = () => {
  const isOpenWalletModalOpen = useWalletModal((s) => s.isOpen);
  const isAccountModalOpen = useAccountModal((s) => s.isOpen);
  const isOnboardingModalOpen = useOnboardingModal((s) => s.isOpen);
  const isBuyModalOpen = useBuyModal((s) => s.isOpen);
  return (
    <>
      <DevUtils />
      <LazyComponent isOpen={isOpenWalletModalOpen}>
        <WalletModal />
      </LazyComponent>
      <LazyComponent isOpen={isAccountModalOpen}>
        <AccountModal />
      </LazyComponent>
      <LazyComponent isOpen={isOnboardingModalOpen}>
        <OnboardingModal />
      </LazyComponent>
      <LazyComponent isOpen={isBuyModalOpen}>
        <BuyModal />
      </LazyComponent>
    </>
  );
};
