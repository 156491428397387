import { Close } from '@ballz-app/icons';
import { HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { useBallzToast } from '@ui/hooks/useBallzToast';

export const Disclaimer = () => {
  const toast = useBallzToast();

  return (
    <HStack justify="center" bg="#0ED8C3" height="40px" py="25px">
      <Text color="#3B254C" fontSize="14px">
        Remember to read
      </Text>
      <Text
        fontWeight="700"
        textDecoration="underline"
        cursor="pointer"
        color="#3B254C"
        fontSize="14px"
        onClick={() =>
          toast({
            status: 'success',
            duration: 6000,
            isClosable: true,
            render: ({ onClose }) => {
              return (
                <VStack
                  position="relative"
                  maxWidth="350px"
                  p={4}
                  borderRadius={20}
                  borderWidth={2}
                  alignItems="center"
                  gap={0}
                  mb="55px"
                  zIndex="100000"
                  className="disclaimer"
                  bg="#CDFFF9"
                  border="2px solid #47FCE9"
                >
                  <Text
                    color="#3B254C"
                    fontSize="12px"
                    fontWeight="700"
                    width="100%"
                  >
                    Disclaimer
                  </Text>
                  <Text color="#3B254C" fontSize="12px" fontWeight="400">
                    You acknowledge that your in-game purchase will be made
                    using your Telegram Stars. Telegram Stars are purchased from
                    and provided by Telegram only. Ballz of Steel does not have
                    access or control to your Telegram Stars and you should
                    comply with any terms and conditions applicable to you set
                    out by Telegram.
                  </Text>
                  <Icon
                    position="absolute"
                    right="15px"
                    top="15px"
                    onClick={onClose}
                    cursor="pointer"
                    as={Close}
                    fontSize={12}
                    color="brand.purple"
                  />
                </VStack>
              );
            },
          })
        }
      >
        this
      </Text>
      <Text color="#3B254C" fontSize="14px">
        before you buy!
      </Text>
    </HStack>
  );
};
