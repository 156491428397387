/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {
  Box,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import CloseButton from '@public/images/close-button.png';
import { useMemo } from 'react';

import { Disclaimer } from './Disclaimer';
import { EpicOffer } from './EpicOffer';
import { ImagePanel } from './ImagePanel';
import { RegularOffer } from './RegularOffer';
import { SellLoading, SellLoadingTextAndIcon } from './SellLoading';
import { useBuyModal } from './useBuyModal';
import { useGetCommodities } from './useGetCommodities';
import { useLoadingStore } from './useLoadingStore';
import { formatCommodities } from './utils';

export const BuyModal = () => {
  const [isOpen, closeModal] = useBuyModal((s) => [s.isOpen, s.closeModal]);
  const { data: commodities, isLoading } = useGetCommodities();
  const [normalCommodities, epicCommodities] = useMemo(() => {
    return formatCommodities(commodities);
  }, [commodities]);
  const showLoading = useLoadingStore((s) => s.showLoading);

  return (
    <Modal
      size="full"
      scrollBehavior="inside"
      onClose={closeModal}
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent
        containerProps={{
          zIndex: 10001,
        }}
      >
        <Image
          cursor="pointer"
          position="absolute"
          src={CloseButton.src}
          alt="mission notification"
          right="13px"
          top="20px"
          width="60px"
          height="60px"
          onClick={closeModal}
        />
        <ImagePanel />

        {isLoading ? (
          <SellLoading flex="1" height="100%" width="100%" />
        ) : (
          <Box flex="1" height="100%" overflow="scroll">
            <EpicOffer commodities={epicCommodities} />
            <RegularOffer commodities={normalCommodities} />
          </Box>
        )}

        <Disclaimer />
        {showLoading && (
          <VStack
            position="absolute"
            height="100vh"
            width="100vw"
            bg="rgba(0, 0, 0, 0.8)"
            justify="center"
          >
            <SellLoadingTextAndIcon
              wordingProps={{
                color: 'white',
              }}
            />
          </VStack>
        )}
      </ModalContent>
    </Modal>
  );
};
