export const textStyles = {
    // display headline
    d1: {
        fontSize: { base: 54, lg: 90 },
        fontWeight: 'semibold',
        lineHeight: { base: '64px', lg: '108px' },
    },
    d2: {
        fontSize: { base: 32, lg: 54 },
        fontWeight: 'semibold',
        lineHeight: { base: '40px', lg: '64px' },
    },
    // page headline
    h1: {
        fontSize: { base: 28, lg: 48 },
        fontWeight: 'semibold',
        lineHeight: { base: '32px', lg: '55px' },
    },
    h2: {
        fontSize: { base: 22, lg: 40 },
        fontWeight: 'semibold',
        lineHeight: { base: '26px', lg: '48px' },
    },
    // section headline
    h3: {
        fontSize: { base: 18, lg: 28 },
        fontWeight: 'semibold',
        lineHeight: { base: '24px', lg: '32px' },
    },
    // dialog headings, card headings and section headings
    h4: {
        fontSize: { base: 16, lg: 20 },
        fontWeight: { base: 'bold', lg: 'semibold' },
        lineHeight: { base: '24px', lg: '26px' },
    },
    // subheading under headlines
    subhead1: {
        fontSize: { base: 18, lg: 32 },
        fontWeight: 'normal',
        lineHeight: { base: '24px', lg: '40px' },
    },
    subhead2: {
        fontSize: { base: 16, lg: 24 },
        fontWeight: 'normal',
        lineHeight: { base: '24px', lg: '32px' },
    },
    // body text (e.g. introduction)
    body1: {
        fontSize: { base: 14, lg: 16 },
        fontWeight: 'normal',
        lineHeight: { base: '20px', lg: '24px' },
    },
    body1Emphasis: {
        fontSize: { base: 14, lg: 16 },
        fontWeight: 'semibold',
        lineHeight: { base: '20px', lg: '24px' },
    },
    // captions and supplementary text, T&C
    caption1: {
        fontSize: { base: 12, lg: 14 },
        // fontWeight: 'normal',
        lineHeight: { base: '16px', lg: '20px' },
    },
    // microcopy (e.g. avatar initials)
    caption2: {
        fontSize: { base: 10, lg: 12 },
        fontWeight: 'light',
        lineHeight: { base: '14px', lg: '16px' },
    },
};
