import {
  Image,
  keyframes,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';

export const loadingDots = keyframes`
  0% { content: ''; }
  33% { content: '.'; }
  66% { content: '..'; }
  90% { content: '...'; }
  `;

export const Loading = () => {
  const imageUrl = useBreakpointValue({
    base: '/images/game/loading.svg',
    md: '/images/game/tablet-loading.svg',
  });

  return (
    <VStack position="relative" height="100vh" width="100vw">
      <Image
        width="100vw"
        height="100vh"
        alt="loading"
        objectFit="cover"
        src={imageUrl}
        maxWidth="1024px"
      />
      <Image
        src="/images/loading-ball.svg"
        position="absolute"
        bottom="90px"
        left="50%"
        transform="translateX(-50%)"
        alt="loading-ball"
      />
      <Text
        position="absolute"
        bottom="35px"
        left="50%"
        transform="translateX(-50%)"
        fontSize="20px"
        fontWeight={500}
        color="#3B264C"
        _after={{
          content: "'.'",
          animation: `${loadingDots} 1.5s steps(1, end) infinite`,
        }}
      >
        Loading
      </Text>
    </VStack>
  );
};
