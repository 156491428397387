import { VStack } from '@chakra-ui/react';

import { StrokeText } from '../StrokeText';
import { CommodityItem } from './CommodityItem';
import type { FormattedCommodity } from './utils';

export const EpicOffer = ({
  commodities,
}: {
  commodities: FormattedCommodity[];
}) => {
  if (!commodities.length) {
    return null;
  }

  return (
    <VStack bg="#EFE2FF" spacing="12px" p="20px">
      <StrokeText
        fontSize="23px"
        strokeWidth="5px"
        strokeColor="#3B254C"
        color="white"
        height="20px"
        mb="20px"
      >
        EPIC ONE TIME DEAL!
      </StrokeText>
      {commodities.map((commodity) => (
        <CommodityItem commodity={commodity} key={commodity.key} isDiscount />
      ))}
    </VStack>
  );
};
