import { fontFamily } from '@ballz-app/chakra';
import {
  Box,
  HStack,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';

import { CTAButton } from './components/CTAButton';
import { useOnboardingModal } from './useOnboardingModal';

export const OnboardingModal = () => {
  const isOpen = useOnboardingModal((s) => s.isOpen);
  const closeModal = useOnboardingModal((s) => s.closeModal);

  return (
    <Modal
      scrollBehavior="inside"
      onClose={closeModal}
      isOpen={isOpen}
      closeOnOverlayClick={false}
    >
      <ModalOverlay background="rgba(0, 0, 0, 0.85)" />
      <ModalContent w="339px" borderRadius="20px" bg="transparent">
        <Image src="/images/game/onboarding-banner.svg" alt="banner" />
        <VStack
          bg="#FFF"
          height="235px"
          borderRadius="0px 0px 20px 20px"
          padding="30px 0px"
          spacing={0}
        >
          <Text
            fontFamily={fontFamily.heading}
            color="#3B254C"
            fontSize="25px"
            lineHeight="89%"
            width="274px"
            textAlign="center"
          >
            A WELCOME GIFT MAKES THINGS BETTER
          </Text>
          <HStack mt="14px">
            <Text
              position="relative"
              top="-8px"
              mt="24px"
              color="#3B254C"
              fontFamily={fontFamily.heading}
              fontSize="48px"
              lineHeight="89%"
            >
              200
            </Text>
            <Image
              src="/images/game/onboarding-ball.svg"
              alt="ball"
              boxSize="46px"
            />
          </HStack>
          <Box>
            <CTAButton onClick={closeModal} />
          </Box>
        </VStack>
      </ModalContent>
    </Modal>
  );
};
