import { create } from 'zustand';

export enum TelegramStatus {
  Initial = 'Initial',
  Telegram = 'Telegram',
  NotTelegram = 'NotTelegram',
}

interface TgInfoStore {
  user: TgUser;
  setUser: (user: TgUser) => void;
  startParam?: string;
  setStartParam: (param: string) => void;
  initData?: string;
  setInitData: (param: string) => void;
  isTelegram: TelegramStatus;
  setTelegram: (status: TelegramStatus) => void;
}

export const useTgInfoStore = create<TgInfoStore>((set) => ({
  user: {
    id: undefined,
    first_name: undefined,
    last_name: undefined,
    username: undefined,
    photo_url: undefined,
  },
  isTelegram: TelegramStatus.Initial,
  setTelegram: (status: TelegramStatus) => {
    set({
      isTelegram: status,
    });
  },
  setUser: (user) => {
    set({ user });
  },
  setStartParam: (param) => {
    set({ startParam: param });
  },
  setInitData: (param) => {
    set({ initData: param });
  },
}));
