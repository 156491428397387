import { fontFamilyVariable } from '@ballz-app/chakra';
import { Global } from '@emotion/react';
import localFont from 'next/font/local';

const Rubik = localFont({
  src: [
    {
      path: './fonts/Rubik-Regular.ttf',
      weight: '100 400',
      style: 'normal',
    },
    {
      path: './fonts/Rubik-Medium.ttf',
      weight: '500',
      style: 'normal',
    },
    {
      path: './fonts/Rubik-SemiBold.ttf',
      weight: '600',
      style: 'normal',
    },
    {
      path: './fonts/Rubik-Black.ttf',
      weight: '900',
      style: 'normal',
    },
    {
      path: './fonts/Rubik-Bold.ttf',
      weight: '600 900',
      style: 'italic',
    },
  ],
  display: 'swap',
  preload: true,
});

const luckiestGuy = localFont({
  src: [
    {
      path: './fonts/LuckiestGuy-Regular.ttf',
      weight: '400',
      style: 'normal',
    },
  ],
  display: 'swap',
  preload: true,
});

export const Fonts = () => {
  return (
    <Global
      styles={`
      :root {
        ${fontFamilyVariable.default}: ${Rubik.style.fontFamily};
        ${fontFamilyVariable.heading}: ${luckiestGuy.style.fontFamily};
        ${fontFamilyVariable.slogan}: ${luckiestGuy.style.fontFamily};
        ${fontFamilyVariable.luckiestGuy}: ${luckiestGuy.style.fontFamily};
      }
    `}
    />
  );
};
