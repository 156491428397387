import { fontFamily } from '@ballz-app/chakra';
import { Box } from '@chakra-ui/react';
import { StrokeText } from '@ui/components/StrokeText';

interface CTAButtonProps {
  onClick: () => void;
}

export const CTAButton = ({ onClick }: CTAButtonProps) => {
  return (
    <Box
      padding="16px 20px 10px 20px"
      width="255px"
      borderRadius="16px"
      border="3px solid  #3B254C"
      background="#F8478B"
      boxShadow="4px 4px 0px 0px #FF7BAE"
      textAlign="center"
      onClick={onClick}
      cursor={'pointer'}
    >
      <StrokeText
        color={'#47FCE9'}
        fontSize="25px"
        lineHeight="89%"
        strokeWidth="6px"
        strokeColor={'#3B254C'}
        fontWeight="400"
        fontFamily={fontFamily.heading}
      >
        USE YOUR BALLZ
      </StrokeText>
    </Box>
  );
};
