import { VStack } from '@chakra-ui/react';

import { StrokeText } from '../StrokeText';
import { CommodityItem } from './CommodityItem';
import type { FormattedCommodity } from './utils';

export const RegularOffer = ({
  commodities,
}: {
  commodities: FormattedCommodity[];
}) => {
  if (!commodities.length) return null;
  return (
    <VStack
      bg="#47FCE9"
      spacing="12px"
      p="20px"
      height="fit-content"
      minH="100%"
    >
      <StrokeText
        fontSize="23px"
        strokeWidth="5px"
        strokeColor="#3B254C"
        color="white"
        height="20px"
        mb="20px"
      >
        REGULAR OFFER
      </StrokeText>
      {commodities.map((commodity) => (
        <CommodityItem commodity={commodity} key={commodity.key} />
      ))}
    </VStack>
  );
};
