import { currentWallet, useDesiredChain } from '@ballz-app/wallet';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { WCRO_DECIMALS } from '@ui/config/sc';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';

export const useWcroBalance = (options: UseQueryOptions<Fraction> = {}) => {
  const desiredChainId = useDesiredChain();
  const account = currentWallet.useAccount();

  return useQuery<Fraction>({
    queryKey: ['useWcroBalance', desiredChainId, account],
    queryFn: async () => {
      const sdk = getSDK(desiredChainId);
      const wcro = sdk.getWCRO();

      const balance = await wcro.balanceOf(account ?? '');

      return new Fraction(balance).shr(WCRO_DECIMALS);
    },
    ...options,
    enabled: options.enabled === false ? false : !!account,
  });
};
