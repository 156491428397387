import { AdsGram } from './AdsGram';
import { GoogleAnalytics } from './GoogleAnalytics';
import { GoogleTagManager } from './GoogleTagManager';
import { TGWebApp } from './TGWebApp';
import { VConsole } from './VConsole';

export const Scripts = () => (
  <>
    <GoogleTagManager />
    <GoogleAnalytics />
    <VConsole />
    <TGWebApp />
    <AdsGram />
  </>
);
