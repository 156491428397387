import { currentWallet } from '@ballz-app/wallet';
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@chakra-ui/react';

import { useAccountModal } from '../useAccountModal';
import { MyBalance } from './MyBalance';
import { VvsBalance } from './VvsBalance';
import { WcroBalance } from './WcroBalance';

export const DefaultContent = () => {
  const closeModal = useAccountModal((s) => s.closeModal);

  return (
    <>
      <ModalHeader>
        <Text textStyle="h4">Your wallet</Text>
      </ModalHeader>
      <ModalBody className="no-scrollbar">
        <MyBalance />
        <WcroBalance mt="20px" />
        <VvsBalance mt="20px" />
      </ModalBody>
      <ModalFooter bottom="0">
        <Button
          variant="ghost"
          mx="auto"
          w="80%"
          onClick={async () => {
            await currentWallet.disconnect();
            closeModal();
          }}
        >
          Disconnect wallet
        </Button>
      </ModalFooter>
    </>
  );
};
