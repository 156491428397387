export interface Commodity {
  ball_amount: number;
  discount_star_amount: number;
  star_amount: number;
}

export enum DiscountType {
  FIRST_PURCHASE = 'FIRST_PURCHASE',
  HALF_PRICE = 'HALF_PRICE',
  NORMAL = 'NORMAL',
}

export interface GetCommodityData {
  discount: number;
  discount_type: DiscountType;
  commodities: Commodity[];
}
