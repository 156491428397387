import posthog from 'posthog-js';
import { isProduction, posthogProjectApiKey } from './env-config';
export let postHogSingleton;
export const initPostHog = () => {
    if (typeof window === 'undefined' || !posthogProjectApiKey || !isProduction) {
        return;
    }
    if (postHogSingleton)
        return;
    posthog.init(posthogProjectApiKey, {
        api_host: 'https://app.posthog.com',
        // disable_session_recording: true,
    });
    return (postHogSingleton = posthog);
};
