import {
  TelegramStatus,
  useTgInfoStore,
} from '@ui/views/Index/TgInfoView/useTgInfoStore';
import { useEffect } from 'react';

/**
 * ref: https://core.telegram.org/bots/webapps#initializing-mini-apps
 */

declare global {
  interface TgUser {
    id?: number;
    first_name?: string;
    last_name?: string;
    username?: string;
    photo_url?: string;
  }
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Telegram?: {
      WebApp: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [x: string]: any;
        initDataUnsafe: {
          user: TgUser;
          start_param: string;
        };
        initData: string;
        ready: () => void;
      };
    };
  }
}

export const TGWebApp = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Load the Telegram WebApp SDK
      const script = document.createElement('script');
      script.src = 'https://telegram.org/js/telegram-web-app.js';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        // The SDK is ready to be used
        const tg = window.Telegram?.WebApp;
        console.log('Telegram WebApp initialized:', tg);
        tg?.ready();
        tg?.setBackgroundColor('rgba(255, 255, 255, 0.5)');
        // Accessing the user's information
        const user = tg?.initDataUnsafe.user;
        useTgInfoStore.getState().setUser({
          id: user?.id,
          username: user?.username,
          first_name: user?.first_name,
          last_name: user?.last_name,
          photo_url: user?.photo_url,
        });
        useTgInfoStore
          .getState()
          .setTelegram(
            user?.id ? TelegramStatus.Telegram : TelegramStatus.NotTelegram,
          );
        if (tg?.initDataUnsafe.start_param) {
          useTgInfoStore
            .getState()
            .setStartParam(tg.initDataUnsafe.start_param);
        }
        if (tg?.initData) {
          useTgInfoStore.getState().setInitData(tg.initData);
        }
      };
    }
  }, []);
  return <></>;
};
