import { Router } from 'next/router';
import type { StateCreator, StoreMutatorIdentifier } from 'zustand';

// @see https://github.com/pmndrs/zustand/blob/main/docs/typescript.md#middleware-that-does-not-change-the-store-type
export const resetWhenRouterChange_raw = <
  T,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = [],
>(
  initializer: StateCreator<T, Mps, Mcs>,
) => {
  let currentUrl =
    typeof window === 'undefined' ? '' : window.location.pathname;

  const newFn: StateCreator<T, Mps, Mcs> = (set, get, store, ...args) => {
    Router.events.on('routeChangeComplete', (url: string) => {
      if (getRoutePath(currentUrl) === getRoutePath(url)) {
        return;
      }
      currentUrl = url;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      store.setState(initializer(set, get, store, ...args), true);
    });
    return initializer(set, get, store, ...args);
  };

  return newFn;
};

const getRoutePath = (url: string) => {
  return (url || '').replace(/\?.*/, '');
};
