import { fontFamily } from '@ballz-app/chakra';
import type { TextProps } from '@chakra-ui/react';
import { Text, useBreakpointValue } from '@chakra-ui/react';

export const StrokeText = ({
  color,
  fontSize,
  lineHeight,
  strokeWidth,
  strokeColor,
  ...props
}: TextProps & {
  strokeWidth: string | Record<string, string>;
  strokeColor: string;
}) => {
  const responsiveStrokeWidth = useBreakpointValue(
    typeof strokeWidth === 'object' ? strokeWidth : { base: strokeWidth },
  );

  return (
    <Text
      fontFamily={fontFamily.heading}
      color={color}
      fontSize={fontSize}
      lineHeight={lineHeight}
      style={{
        WebkitTextStrokeWidth: responsiveStrokeWidth,
        WebkitTextStrokeColor: strokeColor,
        paintOrder: 'stroke fill',
      }}
      {...props}
    />
  );
};
