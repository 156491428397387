import { useCallback } from 'react';

import { type ErrorResponse } from './query/type';
import { useBallzToast } from './useBallzToast';

export const useHandleError = () => {
  const toast = useBallzToast();

  return useCallback(
    (error: ErrorResponse) => {
      toast({
        description: error.message || 'Something went wrong!',
        status: 'error',
      });
    },
    [toast],
  );
};
