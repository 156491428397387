export const fontFamilyVariable = {
    default: '--cronos-font-default',
    heading: '--cronos-font-heading',
    slogan: '--cronos-font-slogan',
    luckiestGuy: '--cronos-font-luckiestGuy',
};
export const fontFamily = {
    default: `var(${fontFamilyVariable.default})`,
    heading: `var(${fontFamilyVariable.heading})`,
    slogan: `var(${fontFamilyVariable.slogan})`,
    luckiestGuy: `var(${fontFamilyVariable.luckiestGuy})`,
};
