import { currentWallet } from '@ballz-app/wallet';
import { CRO_DECIMALS } from '@ui/config/sc';
import { Fraction } from 'bi-fraction';
import { useMemo } from 'react';

export const useWalletBalance = (): Fraction | undefined => {
  const balanceBn = currentWallet.useBalance();
  const balance = useMemo(() => {
    return balanceBn !== undefined
      ? new Fraction(balanceBn).shr(CRO_DECIMALS)
      : undefined;
  }, [balanceBn]);

  return balance;
};
