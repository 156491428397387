import BuyModalImg from '@public/images/first-purchase-bg.png';

import { DiscountType } from './types';

export const CommodityColors = {
  [DiscountType.FIRST_PURCHASE]: {
    bg: `url(${BuyModalImg.src})`,
    discountColor: '#47FCE9',
  },
  [DiscountType.HALF_PRICE]: {
    bg: '#F8FFD2',
    discountColor: '#F8478B',
  },
  [DiscountType.NORMAL]: {
    bg: '#F8FFD2',
    discountColor: '#F8478B',
  },
};
