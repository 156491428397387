/**
 * Modal theme
 * @see https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src/components/modal.ts
 */
import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { viewportHeightVariable } from '../utils/useInjectedCssVarOfViewport';
export const Modal = {
    parts: parts.keys,
    baseStyle() {
        return {};
    },
    variants: {},
    sizes: {
        full(props) {
            /**
             * Fix missing modal footer on iOS.
             * @see https://github.com/chakra-ui/chakra-ui/issues/4903
             */
            const isScrollInside = props['scrollBehavior'] === 'inside';
            return {
                dialogContainer: {
                    ...(!isScrollInside && fullStyles),
                },
                dialog: {
                    ...(isScrollInside && fullStyles),
                    my: 0,
                    ml: 0,
                    mr: 0,
                    mt: 0,
                    mb: 0,
                },
            };
        },
    },
    defaultProps: {},
};
const fullStyles = {
    minHeight: 'auto',
    maxHeight: 'unset',
    height: `var(${viewportHeightVariable}, -webkit-fill-available)`,
};
