import type { Commodity, GetCommodityData } from './types';
import { DiscountType } from './types';

export interface FormattedCommodity {
  discountStars: number;
  stars: number;
  discount: number;
  balls: number;
  discountType: DiscountType;
  key: string;
}

export const formatCommodities = (data?: GetCommodityData[]) => {
  const normalCommodities: FormattedCommodity[] = [];
  const epicCommodities: FormattedCommodity[] = [];
  data?.map((commodityData: GetCommodityData) => {
    commodityData.commodities.forEach((commodity: Commodity) => {
      const item = {
        stars: commodity.star_amount,
        discountStars: commodity.discount_star_amount,
        balls: commodity.ball_amount,
        discountType: commodityData.discount_type,
        discount: (1 - commodityData.discount) * 100,
        key: `${commodityData.discount_type}_${commodity.star_amount}_${commodity.ball_amount}`,
      };
      if (commodityData.discount_type === DiscountType.NORMAL) {
        normalCommodities.push(item);
      } else {
        epicCommodities.push(item);
      }
    });
  });
  return [normalCommodities, epicCommodities];
};
