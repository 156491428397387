import type { FlexProps } from '@chakra-ui/react';
import { Button, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { Num } from '@ui/components/Num';
import { EXTERNAL_LINK } from '@ui/config/externalLinks';
import { useWalletBalance } from '@ui/hooks/useWalletBalance';

import { useAccountModal } from '../useAccountModal';

export type MyBalanceProps = FlexProps;
export const MyBalance = (props: MyBalanceProps) => {
  const openDisclaimer = useAccountModal((s) => s.openDisclaimer);
  const balance = useWalletBalance();

  return (
    <Flex justify="space-between" {...props}>
      <Text>My Balance</Text>
      <VStack align="flex-end">
        <HStack>
          <Num value={balance} />
          <Image src="/tokens/cro.svg" boxSize="24px" alt="cro" />
        </HStack>
        <Button
          variant="link"
          color="red.400"
          onClick={() => {
            openDisclaimer(EXTERNAL_LINK.vvsSwapCro);
          }}
        >
          <Text textStyle="caption1">Get CRO</Text>
        </Button>
      </VStack>
    </Flex>
  );
};
